import { Guid } from 'guid-typescript';

import { MethodEnum } from '../../enums/method.enum';

export class ArquivoCreateDto {

  id?: Guid;
  base64: string;
  nome: string;
  extensao: string;
  acao: string;

  constructor(object?: any) {
    if (object) {
      this.id       = object.id;
      this.base64   = object.base64;
      this.nome     = object.nome;
      this.extensao = object.extensao;
      this.acao     =  MethodEnum.CAD;
    }
  }
}
